import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LockOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Card, message, Checkbox } from "antd";
import PhoneInput from "antd-phone-input";
import { registerAccount } from "../../actions";
import {} from "../../global_constants";

import responseErrorService from "../../utilities/responseErrorHandling";
import ReCAPTCHA from "react-google-recaptcha";

const validPhoneNumberValidation = (_, { valid }) => {
  // if (valid(true)) return Promise.resolve(); // strict validation
  if (valid()) return Promise.resolve(); // non-strict validation
  return Promise.reject("Invalid phone number");
};

const Register = () => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const onSubmit = (values) => {
    const phoneNumberObj = values["phone"];
    values["phone"] = `${phoneNumberObj.countryCode}${phoneNumberObj.areaCode}${phoneNumberObj.phoneNumber}`;

    if (!captchaValue) {
      message.error("Please complete the CAPTCHA!");
      return;
    }
    values.captcha = captchaValue;

    setProcessing(true);
    registerAccount(values)
      .then((response) => {
        const { data } = response;
        message.success("Account created successfully! Please check your email to verify your account.", 10);
        history.push("/?extra=verify_email");
      })
      .catch((err) => {
        responseErrorService.handle(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validPasswordValidation = (_, value) => {
    if (!value || form.getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject("The two passwords that you entered do not match!");
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {}, []);

  return (
    <div className="login-page">
      <Card bordered>
        <div className="image-card" style={{ marginBottom: "15px", backgroundColor: "black" }} />
        <p className="text-2xl semi-bold mb-16">Create an Account</p>
        <Form layout="vertical" form={form} onFinish={(values) => onSubmit(values)}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="company_name"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please input your Company Name!",
              },
            ]}
          >
            <Input size="middle" prefix={<ShopOutlined />} placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number!",
              },
              { validator: validPhoneNumberValidation },
            ]}
          >
            <PhoneInput enableSearch />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-Mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters!",
              },
            ]}
          >
            <Input.Password size="middle" prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "Please confirm your Password!",
              },
              {
                validator: validPasswordValidation,
              },
            ]}
          >
            <Input.Password size="middle" prefix={<LockOutlined />} placeholder="Confirm Password" />
          </Form.Item>

          <ReCAPTCHA sitekey="6LcFsCocAAAAAINBl3Qkn4-lGo_W6x9ShKtnXgCt" onChange={onCaptchaChange} />

          <Form.Item name="is_sms_opt_in" valuePropName="checked">
            <Checkbox>
              By clicking here you consent to receive SMS Customer Care messages from manyfastscan. Message frequency may vary. Standard Message and
              Data Rates may apply. Reply STOP to opt out. Reply Help for help.
            </Checkbox>
          </Form.Item>

          <Form.Item noStyle>
            <Button block type="primary" className="mt-8" htmlType="submit" loading={processing}>
              Register
            </Button>
          </Form.Item>

          <p style={{ marginTop: "10px" }}>
            <p>
              <p style={{ marginBottom: "15px" }}>By creating an account you agree to our policies below.</p>
              <a target="_blank" href="https://manyfastscan.com/privacy-policy" style={{ color: "#1677ff" }}>
                Privacy Policy
              </a>
              <br />
              <a target="_blank" href="https://manyfastscan.com/terms-conditions" style={{ color: "#1677ff" }}>
                Terms &amp; Conditions
              </a>
              <br />
              <a target="_blank" href="https://manyfastscan.com/eula" style={{ color: "#1677ff" }}>
                EULA
              </a>
              <br />
              <a target="_blank" href="https://manyfastscan.com/acceptable-use" style={{ color: "#1677ff" }}>
                Acceptable Use
              </a>
            </p>
          </p>
        </Form>
      </Card>
    </div>
  );
};
export default Register;
