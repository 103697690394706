import { useEffect } from "react";
import { useParams } from "react-router";

import { AUTHORIZATION_KEY, DEVICE_ID } from "../../global_constants";
import ZohoAsapWidget from "../../components/zohoAsapWidget";
import instance from "../../axios";

const HelpDirectUse = () => {
  const params = useParams();
  const { jwt } = params;
  const { deviceId } = params;

  useEffect(() => {
    if (jwt && deviceId) {
      localStorage.setItem(AUTHORIZATION_KEY, jwt);
      localStorage.setItem(DEVICE_ID, deviceId);
      instance.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
      instance.defaults.headers.common["X-Device-Id"] = localStorage.getItem(DEVICE_ID);
    }
  }, [jwt, deviceId]);

  return (
    <>
      <ZohoAsapWidget isAuthenticated={jwt} invokeLogoutFirst={true} autoOpen={true} />
    </>
  );
};
export default HelpDirectUse;
