import { useEffect } from "react";
import { getZohoJWT } from "../../actions";
import responseErrorService from "../../utilities/responseErrorHandling";

const ZohoAsapWidget = ({ isAuthenticated, invokeLogoutFirst = false, autoOpen = false }) => {
  const getJwtTokenCallback = (successCallback, failureCallback) => {
    getZohoJWT()
      .then((response) => {
        const { data } = response;
        successCallback(data.token);
      })
      .catch((error) => {
        responseErrorService.handle(error);
        failureCallback(error);
      });
  };

  // Utility function to create a delay
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (!isAuthenticated) return;

    const scriptId = "zohodeskasapscript";
    if (document.getElementById(scriptId)) return; // Prevent multiple inserts

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = scriptId;
    script.defer = true;
    script.nonce = "nMG5dN9DVbYHxRU+bBBY+A==";
    script.src = "https://desk.zoho.com/portal/api/web/asapApp/810567000005821012?orgId=798671935";

    document.body.appendChild(script);

    script.onload = async () => {
      // Poll for Zoho ASAP Widget readiness
      const maxAttempts = 200; // Approximately 20 seconds with 100ms intervals
      let attempts = 0;

      if (autoOpen) {
        // click on a button with id of "zohohc-asap-web-button"
        setTimeout(() => {
          document.getElementById("zohohc-asap-web-button")?.click();
        }, 1000);
      }

      while (!window.ZohoDeskAsapReady && attempts < maxAttempts) {
        await sleep(100);
        attempts++;
      }

      if (window.ZohoDeskAsapReady) {
        window.ZohoDeskAsapReady(() => {
          console.log("Zoho ASAP Widget Loaded");

          if (invokeLogoutFirst) {
            console.log("First Invoking Logout");
            window.ZohoDeskAsap.invoke("logout");
            console.log("Invoking Login");
            window.ZohoDeskAsap.invoke("login", getJwtTokenCallback);
          } else {
            console.log("Not Invoking Logout");
            console.log("Invoking Login");
            window.ZohoDeskAsap.invoke("login", getJwtTokenCallback);
          }
        });
      } else {
        console.error("Zoho ASAP Widget failed to load after maximum attempts");
      }
    };

    return () => {
      // for removal, we have to remove the aside element with id of "zohohc-asap-web-app-main"
      document.getElementById("zohohc-asap-web-app-main")?.remove();
      document.getElementById(scriptId)?.remove();
      // also remove all script and link elements that have src or href that contains "zohocdn"
      const scripts = document.querySelectorAll('script[src*="zohocdn"], link[href*="zohocdn"]');
      scripts.forEach((script) => script.remove());
    };
  }, [isAuthenticated]);

  return null; // No UI elements needed
};

export default ZohoAsapWidget;
